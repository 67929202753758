/**
 * © Antoine Puel — 2020
*/

import Collapse from '../Utils/Collapse';
import Headroom from 'headroom.js';
let headroom;

const initHeadroom = (page) => {
  const headroomOptions = {
    // vertical offset in px before element is first unpinned
    offset: 0,
    // you can specify tolerance individually for up/down scroll
    tolerance: {
      up: 5,
      down: 5
    }
  };

  // select your header or whatever element you wish
  const header = page.querySelector('.header-menu');
  headroom = new Headroom(header, headroomOptions);
  headroom.init();
};

const initDropdown = (page) => {
  function openDropdown(dropdown) {
    const dropdownButton = dropdown.querySelector('button');
    dropdownButton.classList.add('open');
    dropdownButton.setAttribute('aria-expanded', true);
  }

  function closeDropdown(dropdown) {
    const dropdownButton = dropdown.querySelector('button');
    dropdownButton.classList.remove('open');
    dropdownButton.setAttribute('aria-expanded', false);
  }

  const dropdowns = page.querySelectorAll('.header-menu-navlist-item.dropdown');

  // Toggle menu on click
  dropdowns.forEach(dropdown => {
    const dropdownButton = dropdown.querySelector('button');
    dropdownButton.addEventListener('click', function(event) {
      // If other dropdowns are already open, close it
      dropdowns.forEach(dropdown => {
        const dropdownButton = dropdown.querySelector('button');
        const dropdownArrow = dropdownButton.querySelector('svg');
        const dropdownArrowG = dropdownButton.querySelector('svg * g');
        const dropdownArrowPath = dropdownButton.querySelector('svg * path');
        if (event.target !== dropdownButton && event.target !== dropdownArrow && event.target !== dropdownArrowG && event.target !== dropdownArrowPath) {
          closeDropdown(dropdown);
        }
      });

      // Open or close according to current state
      setTimeout(() => {
        if (dropdownButton.classList.contains('open')) {
          closeDropdown(dropdown);
        } else {
          openDropdown(dropdown);
        }
      });
    });
  });

  // Close dropdown if user click outside it
  document.addEventListener('click', function(event) {
    if (!event.target.closest('.dropdown')) {
      dropdowns.forEach(dropdown => {
        closeDropdown(dropdown);
      });
    };
  }, false);

  // Close dropdown if user press ESC key
  document.addEventListener('keyup', function(event) {
    if (event.defaultPrevented) {
      return;
    }

    const key = event.key || event.keyCode;

    if (key === 'Escape' || key === 'Esc' || key === 27) {
      dropdowns.forEach(dropdown => {
        closeDropdown(dropdown);
        // Remove focus from button
        document.activeElement.blur();
      });
    }
  });
};

const initMobile = (page) => {
  const activeClass = 'active';
  const menuToggle = page.querySelector('button.header-menu-toggle');
  const menuOpenIcon = page.querySelector('.header-menu-open-icon');
  const menuCloseIcon = page.querySelector('.header-menu-close-icon');
  const menuMobile = page.querySelector('.menu-mobile');

  menuToggle.addEventListener('click', function() {
    if (!menuMobile.classList.contains(activeClass)) {
      menuMobile.classList.add(activeClass);
      menuToggle.setAttribute('aria-expanded', 'true');
      menuOpenIcon.classList.remove('active');
      menuCloseIcon.classList.add('active');
      document.body.style.overflowY = 'hidden';
      headroom.freeze();
    } else {
      menuMobile.classList.remove(activeClass);
      menuToggle.setAttribute('aria-expanded', 'false');
      menuOpenIcon.classList.add('active');
      menuCloseIcon.classList.remove('active');
      document.body.style.overflowY = '';
      headroom.unfreeze();
    }
  });

  const toggleButtons = page.querySelectorAll('.menu-mobile-navlist-link.toggle');

  // Open sub-menu mobile categories
  toggleButtons.forEach(btn => {
    btn.addEventListener('click', function(event) {
      console.log(this);
      const collapsibleContent = btn.parentNode.querySelector('.collapse');
      btn.classList.toggle('is-open');
      Collapse(collapsibleContent);
    });
  });
};

const init = (page) => {
  initHeadroom(page);
  initDropdown(page);
  initMobile(page);
};

export { init };
