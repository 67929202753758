import Collapse from '../Utils/Collapse';

const ContactView = {
  namespace: 'contact',
  beforeEnter({ next }) {
    const toggleButtons = next.container.querySelectorAll('.btn-toggle');
    toggleButtons.forEach(btn => {
      btn.addEventListener('click', function() {
        const collapsibleContent = btn.parentNode.querySelector('.collapse');
        btn.classList.toggle('active');
        Collapse(collapsibleContent);
      });
    });
  }
};

export default ContactView;
