import CopyClipboard from '../Utils/CopyClipboard';

const initCopyEmails = () => {
  const emails = document.querySelectorAll('.social-medias-email');
  if (emails) {
    emails.forEach((email) => {
      email.addEventListener('click', () => {
        const valueToCopy = email.getAttribute('data-copy-value');
        CopyClipboard(email, valueToCopy);
      });
    });
  }
};

export { initCopyEmails };
