/**
 * Show, hide and toggle collapsible elements
*/

// Show an element
var show = function(elem) {
  // Get the natural height of the element
  var getHeight = function() {
    elem.style.display = 'block'; // Make it visible
    var height = elem.scrollHeight + 'px'; // Get it's height
    elem.style.display = ''; //  Hide it again
    return height;
  };

  var height = getHeight(); // Get the natural height
  elem.classList.add('is-visible'); // Make the element visible
  elem.style.height = height; // Update the max-height

  window.setTimeout(function() {
    elem.style.opacity = '1';
  }, 50);

  // Once the transition is complete, remove the inline max-height so the content can scale responsively
  window.setTimeout(function() {
    elem.style.height = '';
  }, 250);
};

// Hide an element
var hide = function(elem) {
  // Give the element a height to change from
  elem.style.height = elem.scrollHeight + 'px';

  // Set the height back to 0
  window.setTimeout(function() {
    elem.style.height = '0';
    elem.style.opacity = '0';
  }, 10);

  // When the transition is complete, hide it
  window.setTimeout(function() {
    elem.classList.remove('is-visible');
  }, 250);
};

// Toggle element visibility
var toggle = function(elem, timing) {
  // If the element is visible, hide it
  if (elem.classList.contains('is-visible')) {
    hide(elem);
    return;
  }

  // Otherwise, show it
  show(elem);
};

export default toggle;
