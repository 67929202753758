import Splide from '@splidejs/splide';

const EcosystemView = {
  namespace: 'ecosystem',
  beforeEnter({ next }) {
    const galleryOptions = {
      type: 'loop',
      speed: 350,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      perPage: 6,
      perMove: 3,
      keyboard: 'focused',
      pagination: true,
      arrows: false,
      autoplay: true,
      interval: 3000,
      breakpoints: {
        992: {
          perPage: 3,
          perMove: 3
        },
        768: {
          perPage: 2,
          perMove: 2
        }
      }
    };

    const galleries = next.container.querySelectorAll('.splide');
    galleries.forEach(function(gallery) {
      gallery = new Splide(gallery, galleryOptions);
      gallery.mount();
    });
  }
};

export default EcosystemView;
