const THRESHOLD = 0.15;
const observerConfig = {
  root: null,
  rootMargin: '0px',
  threshold: THRESHOLD
};

function observerCallback(entries, observer) {
  entries.forEach(entry => {
    if (entry.intersectionRatio > THRESHOLD) {
      entry.target.classList.add('in-view');
      observer.unobserve(entry.target);
    }
  });
}

// eslint-disable-next-line no-undef
const observer = new IntersectionObserver(observerCallback, observerConfig);

const enterAnimation = (selector) => {
  const elements = document.querySelectorAll(selector);
  elements.forEach(el => {
    observer.observe(el);
  });
};

export { enterAnimation };
