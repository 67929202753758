/**
 * © Antoine Puel — 2020
*/

const FormInput = (() => {
  // Add active class and placeholder
  const handleFocus = (e) => {
    const target = e.target;
    target.parentNode.classList.add('active');
    target.setAttribute('placeholder', target.getAttribute('data-placeholder'));
  };

  // Remove active class and placeholder
  const handleBlur = (e) => {
    const target = e.target;
    if (!target.value) {
      target.parentNode.classList.remove('active');
    }

    if (!target.checkValidity()) {
      target.parentNode.classList.add('invalid');
    } else {
      target.parentNode.classList.remove('invalid');
    }

    target.removeAttribute('placeholder');
  };

  // Add a * to required fields
  const handleRequired = (element) => {
    const label = element.children[0];
    const input = element.children[1];
    if (label && input && input.required) {
      label.classList.add('required');
    }
  };

  // Register events
  const bindEvents = (element) => {
    const floatField = element.querySelector('input');
    floatField.addEventListener('focus', handleFocus);
    floatField.addEventListener('blur', handleBlur);
    handleRequired(element);
  };

  // Get DOM elements
  const init = (page) => {
    const floatContainers = page.querySelectorAll('.floating-label-container');

    floatContainers.forEach((element) => {
      if (element.querySelector('input').value) {
        element.classList.add('active');
      }

      bindEvents(element);
    });
  };

  return {
    init: init
  };
})();

export default FormInput;
