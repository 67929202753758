import Splide from '@splidejs/splide';

const MissionView = {
  namespace: 'mission',
  beforeEnter({ next }) {
    const gallery = new Splide(next.container.querySelector('.splide'), {
      type: 'loop',
      speed: 350,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      perPage: 1,
      perMove: 1,
      pagination: false
    }).mount();

    /* Set first bullet active at load */
    const pagination = next.container.querySelector('.splide__pagination');
    const firstBullet = pagination.querySelector('button[data-index="0"]');
    firstBullet.classList.add('is-active');
    firstBullet.setAttribute('aria-current', true);
    firstBullet.nextElementSibling.classList.add('is-active');

    /* Set bullet active state according to current index */
    gallery.on('move', function() {
      /* Clean active state for all bullets */
      const bullets = pagination.querySelectorAll('button.splide__pagination__page');
      bullets.forEach((bullet) => {
        bullet.classList.remove('is-active');
        bullet.setAttribute('aria-current', false);
        const date = bullet.nextElementSibling;
        date.classList.remove('is-active');
      });

      /* Set active state for active bullet */
      const activeBullet = pagination.querySelector(`button[data-index="${gallery.index}"]`);
      activeBullet.classList.add('is-active');
      activeBullet.setAttribute('aria-current', true);
      const activeDate = activeBullet.nextElementSibling;
      activeDate.classList.add('is-active');
    });
  }
};

export default MissionView;
