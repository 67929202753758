const grecaptchaScript = document.createElement('script');
grecaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LcS0TIaAAAAAEJEE8IPWWxzg_J8GBQ-jJmqQKET');
grecaptchaScript.setAttribute('data-name', 'grecaptcha');

const ContactFormView = {
  namespace: 'contact-form',
  beforeEnter({ next }) {
    /* Create reCAPTCHA script */
    document.head.appendChild(grecaptchaScript);

    /* Run reCAPTCHA when form is submitted */
    const contactForm = next.container.querySelector('form[method="post"');
    contactForm.addEventListener('submit', event => {
      event.preventDefault();
      grecaptcha.ready(function() {
        grecaptcha.execute('6LcS0TIaAAAAAEJEE8IPWWxzg_J8GBQ-jJmqQKET', { action: 'validate_captcha' })
          .then(function(token) {
            document.getElementById('g-recaptcha-response').value = token;
            /* Submit the form now that we got the token */
            contactForm.submit();
          });
      });
    });

    const select = next.container.querySelector('#prestations');
    const optionalFieldsContainer = next.container.querySelector('.contact-form-optional-fields');
    select.addEventListener('change', function() {
      /* Make optional fields visible according to selected option */
      const visibleClass = 'is-visible';
      const selectedOption = this.value;
      const selectedFields = optionalFieldsContainer.querySelector(`#${selectedOption}`);
      if (selectedFields) selectedFields.classList.add(visibleClass);

      /* Hide all other optional fields */
      const optionalFields = optionalFieldsContainer.querySelectorAll('.contact-form-optional');
      optionalFields.forEach(field => {
        if (field && field !== selectedFields) {
          field.classList.remove(visibleClass);
        }
      });
    });
  },
  afterLeave() {
    document.head.removeChild(document.head.querySelector('[data-name="grecaptcha"]'));
  }
};

export default ContactFormView;
