import copy from 'clipboard-copy';

export default function CopyClipboard(element, valueToCopy) {
  // Text inside the element (eg: 'Copy link')
  const elementText = element.innerHTML;
  // Text after the user clicked on the element (eg: 'Copied!')
  const onCopySuccessDisplayText = element.getAttribute('data-copy-success');

  // Copy the text when user click the element
  copy(valueToCopy);
  // Reset focus
  element.blur();
  // Update state of the element with the 'copied text'
  if (onCopySuccessDisplayText) element.innerHTML = onCopySuccessDisplayText;
  // Reset the original element's text after value is copied
  setTimeout(() => {
    element.innerHTML = elementText;
  }, 2500);
}
