import Splide from '@splidejs/splide';

const SolutionView = {
  namespace: 'solution',
  afterEnter({ next }) {
    /* Main gallery */
    const galleryOptions = {
      type: 'loop',
      speed: 350,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      gap: 30,
      perMove: 1,
      autoWidth: true,
      trimSpace: true,
      focus: 'center',
      arrows: true,
      pagination: false
    };

    const gallery = next.container.querySelector('.splide.solution-gallery');

    if (gallery) {
      const mainGallery = new Splide(gallery, galleryOptions);
      mainGallery.mount();
    }

    /* Partners */
    const partnersOptions = {
      type: 'loop',
      speed: 350,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      perPage: 6,
      perMove: 6,
      keyboard: 'focused',
      pagination: true,
      arrows: false,
      breakpoints: {
        992: {
          perPage: 3,
          perMove: 3
        },
        768: {
          perPage: 2,
          perMove: 2
        }
      }
    };

    const partners = next.container.querySelector('.splide.partners-gallery');
    if (partners) {
      const partnersGallery = new Splide(partners, partnersOptions);
      partnersGallery.mount();
    }

    /* Workshops */
    const workshopOptions = {
      type: 'loop',
      speed: 350,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      perPage: 1,
      perMove: 1,
      arrows: true,
      pagination: false
    };

    const workshop = next.container.querySelector('.splide.solution-workshop-gallery');
    if (workshop) {
      const workshopGallery = new Splide(workshop, workshopOptions);
      workshopGallery.mount();
    }

    /* Offers */
    const offersOptions = {
      type: 'loop',
      speed: 350,
      easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
      perPage: 3,
      perMove: 2,
      gap: 30,
      arrows: true,
      pagination: false,
      breakpoints: {
        1200: {
          perPage: 2
        },
        768: {
          perPage: 1,
          perMove: 1
        }
      }
    };

    const offers = next.container.querySelector('.splide.solution-offers-gallery');
    if (offers) {
      const offersGallery = new Splide(offers, offersOptions);
      offersGallery.mount();
    }
  }
};

export default SolutionView;
